<template>
  <div>
    <v-app
      v-if="
        !showBannedAlert && !showUnderMaintenanceMode && has_expired === false
      "
    >
      <v-navigation-drawer
        light
        persistent
        v-model="drawer"
        left
        clipped
        app
        :mini-variant="sidebarMV"
        :expand-on-hover="sidebarMV"
      >
        <side-bar />
        <template
          v-if="
            sidebarMV === false &&
            $auth.getRole() !== 'guardian' &&
            $auth.getRole() !== 'student'
          "
          v-slot:append
        >
          <whats-new />
        </template>
      </v-navigation-drawer>

      <v-app-bar
        color="blue darken-3"
        dark
        app
        dense
        fixed
        :clipped-left="true"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <a
          href=""
          title="Expand/Contract Menu"
          style="text-decoration: none; margin-left: 10px"
          @click.prevent="SIDEBAR_MV(sidebarMV)"
        >
          <v-icon>
            {{
              sidebarMV === true
                ? "keyboard_arrow_right"
                : "keyboard_arrow_left"
            }}</v-icon
          >
        </a>
        <a
          href=""
          title="Expand/Contract Screen"
          style="text-decoration: none; margin-left: 10px"
          @click.prevent="activateFullScreen()"
        >
          <v-icon v-if="!fullScreen">fullscreen</v-icon>
          <v-icon v-else>fullscreen_exit</v-icon>
        </a>

        <v-menu offset-y transition="slide-y-reverse-transition">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on"
              >{{ $auth.loggedInRole().name }}
              <v-icon ml-2 v-if="userRoles.length > 1">arrow_drop_down</v-icon>
            </v-btn>
          </template>
          <v-list v-if="userRoles.length > 1">
            <v-subheader>Switch Role</v-subheader>
            <span v-for="(item, index) in userRoles" :key="index">
              <v-list-item
                @click="(updateRoleModal = true), (fetchForRole = item.slug)"
                v-if="
                  item.slug !== $auth.loggedInRole().slug &&
                  (item.slug !== 'guardian' || $auth.company().id === 1) &&
                  item.slug !== 'sms-user' &&
                  item.slug !== 'sms-reseller'
                "
              >
                <v-list-item-title>
                  <img
                    style="height: 20px; padding-right: 10px"
                    :src="getRolesImage(item.slug)"
                    alt=""
                  />
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </span>
          </v-list>
        </v-menu>

        <v-spacer></v-spacer>
        <v-menu
          v-if="['guardian'].includes($auth.getRole()) && childrenData.length"
          offset-y
          transition="slide-y-reverse-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on"
              >{{ guardian.selectedChild.text }}
              <v-icon ml-2 v-if="childrenData.length > 1"
                >arrow_drop_down</v-icon
              >
            </v-btn>
          </template>
          <v-list v-if="childrenData.length > 1">
            <v-subheader>Switch Role</v-subheader>
            <span v-for="(item, index) in childrenData" :key="index">
              <v-list-item @click="selectChild(item)">
                <v-list-item-title>
                  <img
                    style="height: 20px; padding-right: 10px"
                    :src="item.profile"
                    alt=""
                  />
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </span>
          </v-list>
        </v-menu>
        <v-menu offset-y transition="slide-y-reverse-transition">
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on"
              >{{ batch.name }}
              <v-icon
                ml-2
                v-if="
                  batches.length > 1 &&
                  [
                    'administrator',
                    'accountant',
                    'super',
                    'board-of-director',
                    'vice-administrator',
                    'academic-coordinator',
                  ].includes($auth.getRole())
                "
              >
                arrow_drop_down
              </v-icon>
            </v-btn>
          </template>
          <v-list v-if="batches.length > 1">
            <v-subheader>Select Batch</v-subheader>
            <span v-for="(item, index) in batches" :key="index">
              <v-list-item
                @click="updateBatchModal = item.id"
                v-if="item.id !== batch.id"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </span>
          </v-list>
        </v-menu>

        <v-toolbar-items>
          <v-menu offset-y transition="slide-y-reverse-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <router-link
                  style="color: white"
                  :to="'/student-birthday-list'"
                >
                  <v-badge overlap color="red">
                    <v-icon>cake</v-icon>
                    <span class="badge" v-if="birthDayNotification.count > 0">{{
                      birthDayNotification.count
                    }}</span>
                  </v-badge>
                </router-link>
              </v-btn>
            </template>
          </v-menu>

          <v-menu offset-y transition="slide-y-reverse-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-badge overlap color="red">
                  <v-icon :class="{ bell: totalCount > 0 }">add_alert</v-icon>
                  <span class="badge" v-if="totalCount > 0">{{
                    totalCount
                  }}</span>
                </v-badge>
              </v-btn>
            </template>
            <v-card outlined class="primary--border" style="width: 260px">
              <v-list v-if="notifications.length">
                <span v-for="(n, i) in notifications" :key="i">
                  <v-list-item
                    @click="$router.push({ name: n.link, ...n.query })"
                    style="cursor: pointer"
                  >
                    {{ n.name }} <span class="ibadge">({{ n.count }})</span>
                  </v-list-item>
                </span>
              </v-list>
              <v-list v-else>
                <v-list-item> No new notifications. </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
          <div style="margin-left: 15px; margin-top: 15px">
            {{ date.format("mmmm d, yyyy ddd") }}
          </div>
          <v-menu offset-y transition="slide-y-reverse-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-card style="width: 240px">
              <v-list>
                <v-list-item
                  v-if="$auth.can('staff-update')"
                  @click="$router.push({ name: 'settings' })"
                >
                  <v-list-item-action>
                    <v-icon>settings</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Settings</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push({ name: 'my-account' })">
                  <v-list-item-action>
                    <v-icon>account_circle</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>My Account</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-action>
                    <v-icon>input</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </v-toolbar-items>
      </v-app-bar>

      <v-main>
        <div
          v-if="$route.name === 'bill-generate-v2'"
          class="title-bar-breadcrumb"
        >
          <ul>
            <li>
              <a href="">
                <v-icon class="page-icon">home</v-icon>
                Dashboard
              </a>
            </li>
            <li>
              <a href="">
                <v-icon>keyboard_arrow_right</v-icon>
              </a>
            </li>
            <li>
              <a href="" class="active">
                <v-icon class="page-icon">layers</v-icon>
                Bill Generate
              </a>
            </li>
          </ul>
        </div>

        <v-container fluid fill-height class="grey lighten-4 pa-3">
          <v-layout>
            <transition name="router-anim">
              <router-view :key="$route.fullPath" />
            </transition>
          </v-layout>
        </v-container>
      </v-main>

      <v-footer class="pa-3">
        <v-spacer></v-spacer>
        <div style="text-align: center; font-size: 13px">
          &copy; 2017 - {{ new Date().getFullYear() }} | eAcademy Nepal |
          Version {{ latestVersionNumber }} Release
        </div>
      </v-footer>
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.status"
        :right="true"
        :top="true"
      >
        {{ snackbar.message }}
      </v-snackbar>

      <v-dialog v-model="updateRoleModal" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">{{
            "Are you sure you want to change your current role?"
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              outlined
              small
              @click="updateRoleModal = false"
              >No</v-btn
            >
            <v-btn
              color="warning"
              outlined
              small
              @click="requestNewPermission()"
              >Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showUpdateModal" persistent max-width="290">
        <v-card outlined class="primary--border">
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0"
            >{{ "Are you sure you want to change the current selected batch?" }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              outlined
              small
              @click="updateBatchModal = undefined"
              >No</v-btn
            >
            <v-btn
              color="warning"
              outlined
              small
              @click="selectBatch(updateBatchModal)"
              >Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div
        v-if="
          Object.keys(selectedBatch).length &&
          selectedBatch.is_current_batch === false
        "
        class="alert-system"
      >
        ALERT!!! You are currently working on old batch. <u>{{ batch.name }}</u
        >... You can switch to the current batch from your action bar at the
        top.
      </div>
      <div
        v-else-if="
          Object.keys(selectedBatch).length &&
          selectedBatch.is_covid_batch === true &&
          false
        "
        class="alert-system warning-system"
      >
        COVID BATCH ALERT!!! You are currently working on extended batch.
        <u>{{ batch.name }}</u
        >... You may perform (Billing/Exam etc) on this batch till (2078-02-31).
      </div>
    </v-app>
    <h1 v-else-if="showBannedAlert">
      <span style="padding: 50px"
        >ALAS!!! Our Server is currently down. Please contact system
        administrator for more info.</span
      >
    </h1>
    <!-- <DigitalPayment v-else-if="paymentAlert.pendingInvoice.is_expired"></DigitalPayment> -->
    <under-maintenance v-else-if="showUnderMaintenanceMode"></under-maintenance>

    <payment-alert-dialog></payment-alert-dialog>

    <v-dialog
      v-model="paymentSuccessFailResponse.modal"
      persistent
      max-width="700"
    >
      <v-card class="primary--border">
        <v-card-text
          style="margin: 0 !important; padding: 0 !important"
          class="ma-0"
        >
          <div class="payment_success_design">
            <section
              v-if="paymentSuccessFailResponse.type === 'success'"
              class="success-page vertical-page"
            >
              <div class="container">
                <div class="header">
                  <div class="check-outer-tab">
                    <div class="check-tab">
                      <img src="images/check.png" alt="" height="80px" />
                    </div>
                  </div>

                  <div class="body-content">
                    <h1>Payment Success</h1>

                    <p>Payment to eAcademy Nepal was completed successfully.</p>
                  </div>

                  <div class="img-content">
                    <img
                      :src="user.profile_image"
                      alt=""
                      width="50px"
                      height="50px"
                    />
                    <div class="img-text-content">
                      <h1>{{ user.full_name }}</h1>
                      <p>{{ user.email }}</p>
                      <!-- <h1>Amanda Schneider</h1> -->
                      <!-- <p>@schneider</p> -->
                    </div>
                  </div>

                  <div class="favourite">
                    <a href="">
                      <p>Click to Download Voucher.</p>
                    </a>
                  </div>

                  <div class="transfer-content">
                    <h4>Transfer amount</h4>
                    <h1>NRP {{ paymentAlert.totalPaymentAmount }}</h1>
                    <!-- <h5>Nov 24, 2020-9:48 AM</h5> -->
                    <h5>{{ paymentAlert.payment_date }}</h5>
                  </div>
                </div>

                <div class="footer">
                  <a>
                    <h4 @click="transactonDetails">Show transaction details</h4>
                  </a>
                  <div class="footer-border"></div>
                </div>
              </div>
            </section>

            <section
              v-else-if="paymentSuccessFailResponse.type === 'failed'"
              class="success-page vertical-page"
            >
              <div class="container">
                <div class="header">
                  <div class="check-outer-tab">
                    <div class="check-tab-wrong">
                      <img src="images/wrong.png" alt="" height="80px" />
                    </div>
                  </div>

                  <div class="body-content">
                    <h1>Payment Failed</h1>

                    <p>Payment to eAcademy Nepal failed.</p>
                  </div>

                  <div class="img-content">
                    <img
                      :src="user.profile_image"
                      alt=""
                      width="50px"
                      height="50px"
                    />
                    <div class="img-text-content">
                      <h1>{{ user.full_name }}</h1>
                      <p>{{ user.email }}</p>
                    </div>
                  </div>

                  <div class="favourite">
                    <p class="dot-text">. . .</p>
                  </div>

                  <div class="transfer-content">
                    <h4>Payment amount</h4>
                    <h1>NRP {{ paymentAlert.totalPaymentAmount }}</h1>
                  </div>
                </div>

                <div class="footer">
                  <a href="/">
                    <h4>Click to try again.</h4>
                  </a>
                  <div class="footer-border"></div>
                </div>
              </div>
            </section>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import cookie from "js-cookie";
import { mapState, mapActions, mapMutations } from "vuex";
import SideBar from "@/components/SideBar";
import PaymentAlertDialog from "@/components/PaymentAlertDialog";
import WhatsNew from "@/components/whatsnew";
import WhatsNewLib from "@/library/WhatsNew";

import appPackage from "./../../../../package";
import {
  getRolesImage,
  setBatch,
  showUnderMaintenanceMode,
  showBannedAlert,
} from "@/library/helpers";

import UnderMaintenance from "../../../components/UnderMaintenance";
// import {showBannedAlert} from "../../../components/NepaliDatePicker/helper";

const NepaliDate = require("nepali-date-js");

export default {
  components: {
    UnderMaintenance,
    SideBar,
    PaymentAlertDialog,
    WhatsNew,
    // DigitalPayment,
  },
  data: () => ({
    paymentSuccessFailResponse: {
      type: "success",
      modal: false,
    },
    showdp: true,
    drawer: true,
    mv: false,
    updateRoleModal: false,
    updateBatchModal: undefined,
    snackbar: {},
    batches: [],
    date: new NepaliDate(),
    version: appPackage.version,
    notifications: [],
    birthDayNotification: {},
    totalCount: 0,
    fetchForRole: "",
    fiscalYears: [],
    currentFiscalYear: {},
    fullScreen: false,
    has_expired: false,
  }),
  watch: {
    "countData.newEnrollCount"(value) {
      const guardianCountIndex = this.notifications.findIndex(
        (a) => a.id == undefined
      );
      const total_new_enroll_count = this.countData.newEnrollCount;
      // if(guardianCountIndex>0){
      const guardianNotice = this.notifications.splice(guardianCountIndex, 1);

      this.notifications = [
        // ...this.notifications,
        ...guardianNotice,
        {
          link: "admisson-request",
          count: total_new_enroll_count,
          name: "New Admission",
          id: 0,
        },
      ];
      this.totalCount = total_new_enroll_count;
      // }
    },
    "paymentAlert.pendingInvoice"(value) {
      if (Object.keys(value).length) {
        // this.has_expired = value.is_expired
        this.notifications.push({
          link: "settings",
          count: this.paymentAlert.totalPaymentAmount.numberFormat(),
          name: "Pending Payment",
          query: { query: { tab: "payments" } },
        });
        this.totalCount += 1;
      }
    },
    // "paymentAlert.pendingInvoice.is_expired"(value){
    //   console.log(value,'value')
    //   this.showdp = value;
    // }
  },
  created() {},
  mounted() {
    if (this.$route.query.payment_success) {
      this.paymentSuccessFailResponse.modal = true;
      console.log(this.$route.query.payment_success);
      this.paymentSuccessFailResponse.type =
        this.$route.query.payment_success === "true" ? "success" : "failed";
    }

    if (Object.keys(this.paymentAlert.pendingInvoice).length) {
      this.has_expired = this.paymentAlert.pendingInvoice.is_expired;
    }

    if (this.$auth.getRole() === "guardian") {
      this.logout();
      // this.fetchChildren();
    }
    if (this.has_expired === false) {
      this.newEnrollStudentCount();
      this.getBatches();
      this.getBirthdayList();
      this.$events.listen("notification", (payload) => {
        if (typeof payload === "object" && payload.message) {
          this.snackbar = {
            message: payload.message ? payload.message : "",
            status: payload.status ? payload.status : "success",
            show: true,
          };
        }
      });
    }
    this.setUser(this.$auth.user());
    // this.getBatches();

    if (
      ["accountant", "administrator", "super"].includes(
        this.$auth.loggedInRole().slug
      )
    ) {
      if (this.has_expired === false) {
        this.getFiscalYear();
      }
    }

    let $this = this;
    document.addEventListener("fullscreenchange", function (event) {
      document.fullscreenElement
        ? ($this.fullScreen = true)
        : ($this.fullScreen = false);
    });
    // this.newEnrollStudentCount();
  },
  computed: {
    ...mapState([
      "batch",
      "user",
      "accounts",
      "guardian",
      "paymentAlert",
      "sidebarMV",
      "students",
      "countData",
    ]),
    // total_count = this.$store.students.totalStudentCount,
    showBannedAlert,
    showUnderMaintenanceMode,
    latestVersionNumber: WhatsNewLib.latestVersionNumber,
    userRoles() {
      return this.$auth.user().roles;
    },
    showUpdateModal() {
      return typeof this.updateBatchModal === "number";
    },
    selectedBatch() {
      let output = {};
      this.batches.map((item) => {
        if (parseInt(this.batch.id) === item.id) {
          output = item;
        }
      });
      return output;
    },
    childrenData() {
      return this.guardian.children.map((res) => {
        return {
          text: res.full_name,
          value: res.id,
          studentInfo: res.academic ? res.academic : "test",
          profile: res.profile_image,
        };
      });
    },
    selectedChild() {
      const child = this.$storage.get("_s_ch");
      return JSON.parse(child);
    },
  },

  methods: {
    ...mapActions([
      "setBatch",
      "setBatches",
      "setUser",
      "fetchChildren",
      "newEnrollStudentCount",
    ]),
    ...mapMutations([
      "SET_FISCAL_YEAR",
      "SET_FISCAL_YEARS_ALL",
      "SET_UNASSIGNED_GUARDIAN",
      "SELECT_CHILD",
      "SIDEBAR_MV",
    ]),
    getRolesImage,
    transactonDetails() {
      this.paymentSuccessFailResponse.modal = false;
      this.$router
        .push({ path: "/settings", query: { tab: "payments" } })
        .catch();
    },
    logout() {
      this.$storage.delete("_token");

      //do not remove batch since batch should not be reset every time the user logs in
      // this.$storage.delete("batch");
      this.$storage.delete("_permissions");
      this.$storage.delete("_user");
      this.$storage.delete("_role");
      this.$storage.delete("_currentFiscalYear");
      this.$router.replace({ name: "login" });
      this.$storage.sessionRemove("_batch_se");
    },
    activateFullScreen() {
      const app = document.getElementById("appBody");
      try {
        if (app.requestFullscreen) {
          this.fullScreen ? document.exitFullscreen() : app.requestFullscreen();
        } else if (app.mozRequestFullScreen) {
          /* Firefox */
          this.fullScreen
            ? document.exitFullscreen()
            : app.mozRequestFullScreen();
        } else if (app.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          this.fullScreen
            ? document.exitFullscreen()
            : app.webkitRequestFullscreen();
        } else if (app.msRequestFullscreen) {
          /* IE/Edge */
          this.fullScreen
            ? document.exitFullscreen()
            : app.msRequestFullscreen();
        }
      } catch (e) {
        // console.log(e);
      }
    },
    getBatches() {
      this.$rest.get("/api/batch?slim=true").then(({ data }) => {
        let currentBatch = {};
        this.batches = data.data.map((item) => {
          if (item.is_current_batch && !item.is_hs)
            currentBatch = {
              id: item.id,
              start: item.batch_start,
              end: item.batch_end,
              name: item.name,
              is_hs: item.is_hs,
              is_current_batch: item.is_current_batch,
              is_covid_batch: item.is_covid_batch,
            };
          return {
            id: item.id,
            start: item.batch_start,
            end: item.batch_end,
            name: item.name,
            is_hs: item.is_hs,
            is_current_batch: item.is_current_batch,
            is_covid_batch: item.is_covid_batch,
          };
        });

        this.setBatches(data.data);
        let b = this.$storage.sessionGet("_batch_se");
        if (b && b.id) {
          if (b) b = JSON.parse(b);
        } else {
          b = this.$storage.get("batch");
          if (b) b = JSON.parse(b);
        }
        // console.log(b, "here");

        if (b && b.id) {
          this.setBatch({
            id: b.id,
            name: b.name,
            start: b.start,
            end: b.end,
            is_hs: b.is_hs,
            is_current_batch: b.is_current_batch,
          });
        } else {
          if (currentBatch.hasOwnProperty("id")) {
            this.setBatch(currentBatch);
          } else {
            if (this.$auth.getRole() !== "super") {
              this.setBatch({
                id: this.batches[0].id,
                name: this.batches[0].name,
                start: this.batches[0].batch_start,
                end: this.batches[0].batch_end,
                is_hs: this.batches[0].is_hs,
                is_current_batch: this.batches[0].is_current_batch,
                is_covid_batch: this.batches[0].is_covid_batch,
              });
            }
          }
        }
      });
    },
    getFiscalYear() {
      this.$rest
        .get("/api/fiscal-year")
        .then(({ data }) => {
          console.log(data);
          let currentFiscalYear = null;
          this.fiscalYears = data.data.map((item) => {
            if (item.is_current === true) {
              currentFiscalYear = { id: item.id, text: item.name };
            }
            return { id: item.id, text: item.name };
          });
          this.SET_FISCAL_YEARS_ALL(this.fiscalYears);

          let savedFiscalYear =
            window.localStorage.getItem("_currentFiscalYear");
          if (savedFiscalYear) {
            currentFiscalYear = JSON.parse(savedFiscalYear);
          }

          if (currentFiscalYear) this.SET_FISCAL_YEAR(currentFiscalYear);
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    selectFiscalYear(id) {
      let fiscalYear = this.fiscalYears.find((item) => item.id === id);
      this.SET_FISCAL_YEAR(fiscalYear);
    },
    birthday() {
      this.$router.push({ name: "student-birthday-list" });
    },
    getBirthdayList() {
      this.$rest.get("/api/report/notification").then(({ data }) => {
        this.birthDayNotification = data.data.birthday;
        let $this = this;

        data.data.others.map(function (item) {
          $this.notifications.push(item);
          if (item.link === "student-no-guardian-assign") {
            $this.SET_UNASSIGNED_GUARDIAN(item);
          }
        });
        this.totalCount += data.total_count;
      });
    },
    requestNewPermission() {
      this.$rest
        .get("/api/me/permissions?for=" + this.fetchForRole)
        .then(({ data }) => {
          this.$auth.setPermission(data.permissions);
          this.getFiscalYear();
          // window.localStorage.setItem('_currentFiscalYear', window.JSON.stringify(data.fiscal_year));
          location.reload();
        })
        .finally(() => {
          this.$auth.setRole(this.fetchForRole);
          this.updateRoleModal = false;
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },

    selectBatch(id) {
      let b = this.batches.find((item) => item.id === id);
      this.$storage.set("batch", JSON.stringify(b));
      setBatch(b);
      this.setBatch(b);
      location.reload();
    },

    selectChild(item) {
      const student = { value: item.value, text: item.text };
      if (Object.keys(item.studentInfo).length > 0) {
        student.grade_id = item.studentInfo.grade_id;
        student.section_id = item.studentInfo.section_id;
        student.batch_id = item.studentInfo.batch_id;
        student.class_teacher_name = item.studentInfo.class_teacher
          ? item.studentInfo.class_teacher.name
          : "";
        student.class_teacher_id = item.studentInfo.class_teacher
          ? item.studentInfo.class_teacher.id
          : "";
        student.class_teacher_mobile = item.studentInfo.class_teacher
          ? item.studentInfo.class_teacher.mobile
          : "";
        student.enroll_code = item.studentInfo
          ? item.studentInfo.enroll_code
          : "";
        student.enroll_id = item.studentInfo ? item.studentInfo.enroll_id : "";
      }
      this.SELECT_CHILD(student);
      this.$storage.set("_s_ch", JSON.stringify(student));
    },

    async getNewAdmissionCount() {
      // this.notifications = [];
      var total_new_enroll_count = this.countData.newEnrollCount;
      console.log("total count", total_new_enroll_count);
      this.totalCount = total_new_enroll_count;
      this.notifications.push({
        link: "admisson-request",
        count: total_new_enroll_count,
        name: "New Admission",
        id: 1,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_custom";

div#ndp-nepali-box td.ndp-current a {
  display: inline-block;
  width: 100%;
  color: #e17009;
}

.dashboard_refresh_button {
  position: absolute;
  right: 0;
  z-index: 1;
  button {
    color: #ccc !important;
    &:hover {
      color: #777 !important;
    }
  }
}

.whats_new_update {
  padding-top: 5px;
  cursor: pointer;
  box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.05);
}
.bell {
  display: block;
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin: 0 auto 0;
  color: #9e9e9e;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}
a {
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
}
@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.alert-system {
  background: #ff5252 !important;
  &.warning-system {
    background: #fb8c00 !important;
  }
  padding: 7px 7%;
  position: fixed;
  color: white;
  bottom: 0;
  width: 100%;
  font-weight: bold;
  z-index: 99;
}

.text-danger {
  color: #ff5252 !important;
}

.v-badge--overlap .v-badge__badge {
  display: none;
}

.notification {
  .v-badge__badge {
    display: none;
  }
}

.badge {
  background: red;
  position: absolute;
  width: 14px;
  height: 15px;
  font-size: 9px;
  border-radius: 10px;
  padding-top: 2px;
  padding-left: -20px;
  padding-right: 0;
  margin-left: -8px;
  margin-top: -1px;
}

.ibadge {
  margin-top: -13px;
  padding-left: 4px;
  font-size: 11px;
  padding-top: 1px;
}

.container {
  .profile-tab-container {
    min-height: 180px;
    background: linear-gradient(to right, #18e0f5, #135aa5 80%);
    position: relative;
    display: flex;

    .profile-intro {
      position: absolute;
      bottom: 65px;
      left: 250px;

      h2 {
        font-weight: bold;
        color: white;
      }

      h4 {
        color: white;
        line-height: 10px;
      }
    }

    .edit-profile-button {
      position: absolute;
      bottom: 65px;
      color: white !important;
      right: 150px;

      a {
        text-decoration: none;
        display: inline-block;
        margin-right: 15px;
      }
    }

    .tab-content {
      align-self: flex-end;
      background-color: white;
      height: 55px;
      width: 100%;

      .t-container {
        height: inherit;
        width: 85%;
        margin: auto;
        display: flex;

        .t-hold {
          flex: 3;
        }

        .profile-image {
          width: 150px;
          position: relative;
          cursor: pointer;
          .image {
            background-color: #eee;
            height: 120px;
            width: 120px;
            position: absolute;
            left: 30px;
            border-radius: 120px;
            border: 5px solid white;
            margin-top: -70px;
            z-index: 2;
            overflow: hidden;

            &.uploading {
              background-color: #333;

              h3 {
                color: white;
                margin-top: 40px;
                text-align: center;
              }

              p {
                color: white;
                font-size: 12px;
                text-align: center;
                line-height: 10px;
              }
            }

            img {
              width: 100%;
            }

            &:hover {
              &:after {
                content: "";
                background-color: black;
                opacity: 0.5;
                position: absolute;
                left: 0;
                /*top: 0;*/
                height: 120px;
                width: 120px;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.d-alert-row {
  position: relative;
  &:after {
    content: "";
    // background: #ff9800;
    position: absolute;
    bottom: 4%;
    left: 0;
    height: 92%;
    width: 5px;
  }
}
.row-opacity-less {
  color: #c5c5c5;
}
.d-successd {
  &:after {
    background: #4caf50;
  }
}
.d-warningd {
  &:after {
    background: #ff9800;
  }
}
tr.advance_pending {
  background: rgba(76, 175, 80, 0.08);
}

.d-errord {
  &:after {
    background: #ff5252;
  }
}

.d-primaryd {
  &:after {
    background: #1976d2;
  }
}
</style>